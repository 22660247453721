import styles from './styles.module.css'
import {react , useEffect, useState, useCallback , useRef} from 'react'
import { useSelector } from "react-redux"; 
import { useParams } from "react-router-dom";
import { Header } from "../../components/Headers";
import {LoginPopup } from "../../components/loginPopup/index"
import postServices from "../../services/post.services";  
import { Loader } from "../../components/loader/loader";
import { PostAuthor } from "../../components/PostFormats/PostAuthor";
import BloudPost from "../../components/PostFormats/BloudPost";
import PostFooter from "../../components/PostFormats/PostFooter";
import PostMedia from "../../components/PostFormats/postMedia";
import PostStance from "../../components/PostFormats/postStance";
import PollPost from "../../components/PostFormats/PollPost";
import EventPost from "../../components/PostFormats/EventPost";
let page = 1;

const Search = () => {
    const params = useParams();
    let currentUser = useSelector((state) => state.authentication);
    const [userName, setuserName] = useState("Guest");
    const [userId, setUserId] = useState("");
    const [resultPeople, setResultPeople] = useState([]);
    const [resultPosts, setResultPosts] = useState([]);
    const [showLoginPopup, setshowLoginPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchString = params.s;
    const observer = useRef()
    const [tabs,setTabs] = useState('posts')
    // const lastPost = useCallback(node => {
    //     if (loading) return;
    //     if (observer.current) observer.current.disconnect()
    //     observer.current = new IntersectionObserver(e => {
    //       if (e[0].isIntersecting) {
    //         page++;
    //        // searchPosts(searchString,page)
    //       }
    //     })
    //     if (node) observer.current.observe(node)
    //   }, [loading])
    useEffect(() => {    
        let page =1;
        postServices.searchPeople(searchString,userId,page).
        then(resp=>{ 
            if(resp.status){
                setResultPeople(resp.response)
            }
        })
        .catch(err=>{
            console.error(err)
        })
        postServices.searchPosts(searchString,userId,page).
        then(resp=>{ 
            if(resp.status){
                setResultPosts(resp.response)
            }
        })
        .catch(err=>{
            console.error(err)
        })
    },[searchString])
    useEffect(() => {   
         setuserName(currentUser.user.name);
         setUserId(currentUser.user.session_token); 
    }, [currentUser]);
 
    function toggleLoginPopup(e){ 
        setshowLoginPopup(e)
       }
      
    function postLists(posts) {
        let postList = [];
        console.log(posts)
        if (posts.length > 0) {
          posts.forEach((post, i) => {
            post.currUser = userId;
            let videoObj = { video: post.video, videoThumbnail: post.videoThumbnail }
            let linkObj = { linkPreviewImage: post.linkPreviewImage, linkPreviewText: post.linkPreviewText, linkPreviewDescription: post.linkPreviewDescription, linkPreviewUrl: post.linkPreviewUrl }
            let images = []
            if (post.linkPreviewImage) {
              images.push(post.linkPreviewImage)
            } else if (post.images) {
              images = post.images
            }
            if (post.postType == 1) {
    
    
              if (posts.length === i + 3) {
                postList.push(
                  <div key={post.postId}  className={styles.postWrap} >
                    {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                    <PostAuthor post={post} />
                    <BloudPost postDetail={post} />
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
                  </div>)
              } else {
                postList.push(
                  <div key={post.postId}  className={styles.postWrap}  >
    
                    {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                    <PostAuthor post={post} />
                    <BloudPost postDetail={post} />
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
                  </div>)
              }
            } else if (post.postType == 2) {
              if (posts.length === i + 3) {
                postList.push(
                  <div className={styles.postWrap} key={post.postId} >
                    {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                    <PostAuthor post={post} />
                    <PollPost postDetail={post} />
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
                  </div>
                );
              } else {
                postList.push(
                  <div className={styles.postWrap} key={post.postId}>
                    {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                    <PostAuthor post={post} />
                    <PollPost postDetail={post} />
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
                  </div>
                );
              }
            } else if (post.postType == 3) {
              if (posts.length === i + 3) {
                postList.push(
                  <div className={styles.postWrap} key={post.postId} >
    
                    <EventPost
                      postDetail={post} 
                    />
                    <PostAuthor post={post} />
                    {post.postText && <div className={styles.postTxt}>
                      <p>{post.postText}</p>
                    </div>
                    }
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
                  </div>
                );
              } else {
                postList.push(
                  <div className={styles.postWrap} key={post.postId} >
                     {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                    <EventPost
                      postDetail={post} 
                    />
                    <PostAuthor post={post} />
                    {post.postText && <div className={styles.postTxt}>
                      <p>{post.postText}</p>
                    </div>
                    }
                    <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                    <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup}  />
                  </div>
                );
              }
            }
          });
        }
        return postList;
      }

      function changeTab(t){
        setTabs(t)
      }

    return(
        <>
            <Header userName={userName}  />
            <div className={styles.searchWrap}>
                <div className={styles.searchTabs}>
                    <ul>
                        <li><button className={tabs == 'posts' ? styles.active : ''} onClick={()=>{setTabs('posts')}}>Posts</button></li>
                        <li><button className={tabs == 'people' ? styles.active : ''} onClick={()=>{setTabs('people')}}>People</button></li>
                        <li><button className={tabs == 'groups' ? styles.active : ''} onClick={()=>{setTabs('groups')}}>Groups</button></li>
                    </ul>
                </div>
                {tabs == 'posts' &&  <div className={styles.tabPane}>
                    {resultPosts.length > 0 && postLists(resultPosts)}
                </div> }
                {tabs == 'posts' && 
                    <div className={styles.tabPane}>
                        {resultPosts.length > 0 }
                    </div>
                }
            </div>
        </>
    )
}
export { Search}