import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom'
import {LoginPage} from './screens/Login';
import {PostsPage} from './screens/Posts';
import {PostSingle} from './screens/SinglePost';
import {CreatePost} from './screens/CreatePost';
import {SignUp} from './screens/signUp';
import {Profile} from './screens/profile';
import {HomePage} from './screens/home';
import { history } from './helpers/history';
import { Search } from './screens/search';

function App() {
  return (
    <div>
      
       <Routes> 
          <Route exact path="/" element={<HomePage />} /> 
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/posts" element={<PostsPage />} />
          <Route exact path="/CreatePost" element={<CreatePost />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/search/:s" element={<Search />} />
          <Route  path="/post/:id" element={<PostSingle />} />
      </Routes >
    </div>
  );
}

export default App;
