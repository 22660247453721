import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { Header } from "../../components/Headers";
import defaultImg from "../../assets/icons/default.png";
import userImg from "../../assets/icons/user-default.png";
import { userServices } from "../../services/user.services";
import  { FaPencilAlt } from 'react-icons/fa';

const Profile = () => {
  let currentUser = useSelector((state) => state.authentication);
  const [userData, setUserData] = useState("");
  const [tabActive, setTabActive] = useState('profile');
  useEffect(() => {
    console.log("currentUser", currentUser);
    if (currentUser.loggedIn) {
      userServices
        .getUserData(currentUser.user.id, currentUser.user.session_token)
        .then((resp) => {
          if (resp.status) {
            setUserData(resp.response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentUser]);
  const toggleActiveTab = (tab,e) =>{
    e.preventDefault();
    setTabActive(tab)

  }
  return (
    <>
      <Header userName={currentUser.user.name} />
      <div className={styles.profilePage}>
        <div className={styles.containerSmall}>
          <div id="coverImgsec">
            <div className={styles.coverImage}>
              <img
                src={userData.coverImage ? userData.coverImage : defaultImg}
                alt=""
              />
            </div>
          </div>
          <div className={styles.profileInfo}>
            <div className={styles.profilePic} id="profilePic">
              <div className={styles.imgWrap}>
                <img
                  src={userData.profileImage}
                  className={styles.profileimg}
                  width="150"
                  alt=""
                />
              </div>

              {/* <a href="#" className={styles.addProfilePic"><input type="file" onchange="changeImage(this)" name="file"
                        accept="image/*" id="image" value=""><i className={styles.fa fa-camera"></i></a>  */}
            </div>
            <div className={styles.profName}>
              <h1>
                {userData.name}
                {/* <img width=25 src="../img/icon/influencer1.png" alt="">

                    <img width=25 src="../img/icon/influencer2.png" alt="">  */}
              </h1>
            </div>
          </div>

          <div className={styles.profileDetails}>
            <div className={styles.tabsPanel}>
              <ul className={styles.nav__tab}>
                <li>
                  <a className={tabActive == "profile"? styles.active : ''} onClick={(e)=>{toggleActiveTab('profile',e)}} href="#profile">
                    PROFILE
                  </a>
                </li>
                <li>
                  <a className={tabActive=="interest"? styles.active : ''} onClick={(e)=>{toggleActiveTab('interest',e)}}>INTEREST</a>
                </li>
                <li>
                  <a className={tabActive == "friends"? styles.active : ''} href="#friends" onClick={(e)=>{toggleActiveTab('friends',e)}}> CONNECT</a>
                </li>
              </ul>
            </div>
            <div className={styles.tabContents}>
            {tabActive == "profile" && (<div id="profile" className={styles.tabsingleCont}>
                <div className={styles.myAlias}>
                  <div className={styles.innerWrap}>
                    <h3>My alias</h3>
                   {userData.aliasName ? ( <p>
                      <img src={userImg} alt="" />
                      <span>{userData.aliasName}</span>
                    </p>):(
                    <span className={styles.noAlias}>
                      <i className={styles.aliasText}>No alias name added</i>
                      <div className={styles.actionBtns}>
                        <a href="#" className={styles.editAlias}>
                          <FaPencilAlt />
                        </a>
                      </div>
                    </span>)
                  }
                  </div>
                </div>
              </div>
              ) }
              {tabActive == "interest" &&    (  <div id="interest" className={styles.tabsingleCont}>
                <div className={styles.innerWrap}>
                  <h3>MY INTEREST</h3>
                  <ul className={styles.interestList}>
                    <li>test</li>
                  </ul>
                </div>
              </div>) }

{tabActive == "friends" &&    (  <div id="friends" className={styles.tabsingleCont}>
                <div className={styles.tabsingleCont} id="allFriends">
                  <div className={styles.innerWrap}>
                    <h3>ALL FRIENDS</h3>

                    {userData.friendCount > 0 &&    <ul className={styles.firendsList}>
                      <li>
                        <a href="/user">
                          {/* <img src={} alt="" /> */}
                          <span>Friend 1</span>
                        </a>
                      </li>
                    </ul>}
                    {userData.friendCount == 0 &&
                      <p>No Friends added yet</p>
                    }
                  </div>
                </div>
                {/* //checkSelf==1 */}
                <div className={styles.tabsingleCont} id="friendReq">
                  <div className={styles.innerWrap}>
                    <h3>FRIEND REQUESTS</h3>
                <ul className={styles.firendsList}>
                      <li>
                        <a href="/user">
                          <img src={userImg} alt="" />
                          <span>Friend request</span>
                        </a>
                        <div className={styles.friendsAction}>
                          <a href="#" id="acceptReq">
                            Accept
                          </a>
                          <a href="#" id="rejectReq">
                            Decline
                          </a>
                        </div>
                      </li>
                    </ul> 
                  </div>
                </div>
                <div className={styles.tabsingleCont} id="following">
                  <div className={styles.innerWrap}>
                    <h3>Following</h3>
                    <ul className={styles.firendsList}>
                      <li>
                        <a href="/user">
                          <img src={userImg} alt="" />
                          <span>Name</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>)
            }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Profile };
