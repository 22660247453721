import React, { useState, useEffect, useRef } from 'react';
 
import { useDispatch, useSelector } from 'react-redux';
import styles from "./styles.module.css";
import agree from "../../../assets/icons/agree.png";
import disagree from "../../../assets/icons/disagree.png";
import disagreeActive from "../../../assets/icons/disagreeActive.png";
import agreeActive from "../../../assets/icons/agreeActive.png";
import postServices from "../../../services/post.services";
import { useNavigate } from "react-router";
import Speaker from "../../../assets/icons/speaker.png";
import { tr } from 'date-fns/locale';
const PostStance = function (props ) {
  const taRef = useRef(null)
  const navigate = useNavigate()
  let currentUser = useSelector((state) => state.authentication);
  const [stance, setStance] = useState("");
  const [agreeCount, setAgreeCount] = useState(0);
  const [disagreeCount, setDisgreeCount] = useState(0);
  const [agreeImg, setAgreeImg] = useState(agree);
  const [disagreeImg, setDisagreeImg] = useState(disagree);
  const [showCommentTextbox, setShowCommentTextbox] = useState(false);
  function navtigateToPost(id) {  
    if(!props.postDetail.isSinglePage){
      navigate('/post/'+id)
    } else {
      setShowCommentTextbox(true)
      taRef.current.focus()
    }
  } 
  const addComment = ()=>{ 
    let postid = props.postDetail.postId;
    let uid = currentUser.user.session_token;
    let text = taRef.current.value.trim();
    if(text){ 
      postServices.addComment(postid,text,uid , props.parentId).then(resp=>{ 
        props.commentSuccess(true)
      }
      ).catch(err=>{
        
      })
    }
  }

  const takeStance = (postid,sel) => { 
    if(!currentUser.user.session_token) {
      props.setLoginPopup(true)
      return;
    } 
    if(currentUser.user.id == props.postDetail.user_id) return;
    if(props.postDetail.postType == 2 && !props.postDetail.pollData.isPollExpiry) return
    setStance(sel);
    postServices.postVoting(currentUser.user.session_token,postid,sel)
    .then(resp=>{
      if(resp.status){
        setDisgreeCount(resp.response.disagreeCount)
        setAgreeCount(resp.response.agreeCount)
      }
    })
    if (sel == "agree") { 
      setAgreeImg(agreeActive);
      setDisagreeImg(disagree);
    } else { 
        setAgreeImg(agree);
        setDisagreeImg(disagreeActive);
    }
  };
  useEffect(()=>{  
    if(props.parentId){
      setShowCommentTextbox(true)
      taRef.current.focus()
    }
  },[props.parentId])
  useEffect(()=>{ 
    if(props.postDetail){
      setDisgreeCount(props.postDetail.disagreeCount)
      setAgreeCount(props.postDetail.agreeCount)
    }
    if(props.postDetail.isAgree ==1){
      setAgreeImg(agreeActive);
      setDisagreeImg(disagree);
      setStance('agree')
    } else if(props.postDetail.isDisagree ==1){
      setAgreeImg(agree);
      setDisagreeImg(disagreeActive);
      setStance('disagree')
    }
 
  },[props.postDetail])
  return (
    <>
      <div className={[styles.stanceBtn ,props.postDetail.isSinglePage ? styles.isSinglePage : null ].join(' ')}>
      <div className={styles.dFlex}>
      <div className={styles.agree}>
          <div className={styles.stanceCount}>
            <span>{agreeCount}</span>
            <strong>Agree</strong>
          </div>
          <button
            onClick={() => {
              takeStance(props.postDetail.postId,"agree");
            }}
          >
            <img src={agreeImg} />
          </button>
          {stance == "agree" && <div onClick={()=>{navtigateToPost(props.postDetail.postId)}} className={styles.commentBox}></div>}
        </div>
        <div className={styles.disagree}>
          {stance=="disagree" && <div onClick={()=>{navtigateToPost(props.postDetail.postId)}} className={styles.commentBox}></div>}
          <button
            onClick={() => {
              takeStance(props.postDetail.postId,"disagree");
            }}
          >
            <img src={disagreeImg} />
          </button>

          <div className={styles.stanceCount}>
            <span>{disagreeCount}</span>
            <strong>Disagree</strong>
          </div>
        </div>
      </div>
       
        <div className={[styles.textareaContainer , showCommentTextbox? styles.slideUp : null].join(' ')}>
        <textarea ref={taRef}></textarea>
        <button onClick={addComment}><img src={Speaker} /></button>
      </div>
      </div>
   
    </>
  );
};

export default PostStance;
