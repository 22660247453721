// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loading__GKq4k { 
    color: #e90b50; 
}
.styles_faPulse__j9n61{
    animation: styles_fa-spin__VZeWK 1s infinite steps(8);
}

@keyframes styles_fa-spin__VZeWK{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/loader/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IAEI,qDAAuC;AAC3C;;AAEA;IACI;QAEI,uBAAuB;IAC3B;IACA;QAEI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loading { \n    color: #e90b50; \n}\n.faPulse{\n    -webkit-animation: fa-spin 1s infinite steps(8);\n    animation: fa-spin 1s infinite steps(8);\n}\n\n@keyframes fa-spin{\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n    100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `styles_loading__GKq4k`,
	"faPulse": `styles_faPulse__j9n61`,
	"fa-spin": `styles_fa-spin__VZeWK`
};
export default ___CSS_LOADER_EXPORT___;
