// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_postContainer__mAJA1 {
    width: 100%;
    max-width: 650px;
    display: block;
    margin: 0 auto 15px;
}
.styles_pageWrap__yQdhh{
    padding: 140px 15px 0;
}
.styles_postHolder__wWFcL{background-color: #ddd;}
.styles_postWrap__WdYHV{
    padding: 0;
    background: #fff;
    margin: 0 auto 15px;
    border-radius: 5px;
    overflow: hidden; 
}
.styles_loader__tb-jG {
    position: fixed;
    left: 50%;
    top:50%;
    background: rgba(255,255,0, 0.5);
    transform: translate(-50%,-50%);
}
.styles_postTxt__e83qx {
    padding: 0 15px;
    background-color: #E3E1D9;
}
.styles_postTxt__e83qx p {
    margin:0;
}
.styles_loginPopup__zJ3Ff {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    left: 0;
    right:0;
    background: rgba(0,0,0, 0.5);
    z-index: 99999;
    transform: translateY(100%);
    transition:transform 0.4s ease;
}
.styles_slideIn__-0ZNz{
    transform: translateY(0);
}
.styles_pageLoader__lEIz9{
    position: fixed;
    left: 0;
    top:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Posts/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,qBAAqB;AACzB;AACA,0BAAY,sBAAsB,CAAC;AACnC;IACI,UAAU;IACV,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,gCAAgC;IAChC,+BAA+B;AACnC;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,QAAQ;AACZ;AACA;IACI,eAAe;IACf,SAAS;IACT,cAAc;IACd,WAAW;IACX,YAAY;IACZ,OAAO;IACP,OAAO;IACP,4BAA4B;IAC5B,cAAc;IACd,2BAA2B;IAC3B,8BAA8B;AAClC;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,eAAe;IACf,OAAO;IACP,KAAK;IACL,YAAY;IACZ,aAAa;IACb,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".postContainer {\n    width: 100%;\n    max-width: 650px;\n    display: block;\n    margin: 0 auto 15px;\n}\n.pageWrap{\n    padding: 140px 15px 0;\n}\n.postHolder{background-color: #ddd;}\n.postWrap{\n    padding: 0;\n    background: #fff;\n    margin: 0 auto 15px;\n    border-radius: 5px;\n    overflow: hidden; \n}\n.loader {\n    position: fixed;\n    left: 50%;\n    top:50%;\n    background: rgba(255,255,0, 0.5);\n    transform: translate(-50%,-50%);\n}\n.postTxt {\n    padding: 0 15px;\n    background-color: #E3E1D9;\n}\n.postTxt p {\n    margin:0;\n}\n.loginPopup {\n    position: fixed;\n    bottom: 0;\n    margin: 0 auto;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    right:0;\n    background: rgba(0,0,0, 0.5);\n    z-index: 99999;\n    transform: translateY(100%);\n    transition:transform 0.4s ease;\n}\n.slideIn{\n    transform: translateY(0);\n}\n.pageLoader{\n    position: fixed;\n    left: 0;\n    top:0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0,0,0,0.6);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postContainer": `styles_postContainer__mAJA1`,
	"pageWrap": `styles_pageWrap__yQdhh`,
	"postHolder": `styles_postHolder__wWFcL`,
	"postWrap": `styles_postWrap__WdYHV`,
	"loader": `styles_loader__tb-jG`,
	"postTxt": `styles_postTxt__e83qx`,
	"loginPopup": `styles_loginPopup__zJ3Ff`,
	"slideIn": `styles_slideIn__-0ZNz`,
	"pageLoader": `styles_pageLoader__lEIz9`
};
export default ___CSS_LOADER_EXPORT___;
