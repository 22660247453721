import homeIcon  from '../../assets/icons/home.png';
import homeIconActive  from '../../assets/icons/homeActive.png';
import men  from '../../assets/icons/men.png'; 
import menActive  from '../../assets/icons/menActive.png'; 
import bhoup  from '../../assets/icons/bhoup.png';
import bell  from '../../assets/icons/bell.png';
import bellActive  from '../../assets/icons/bellActive.png';
import logout  from '../../assets/icons/logout.png';
import login  from '../../assets/icons/login.png';
import styles from './styles.module.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { history } from '../../helpers/history'; 
import { useDispatch, useSelector } from 'react-redux';
import { userConstants } from '../../constants/user.constants';
export const HeaderMain = (props) =>{   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let currentUser = useSelector((state) => state.authentication); 
    function LogoutFn(){
       dispatch({ type: userConstants.LOGOUT })
       navigate('/login')
    }
    return(
        <div className={styles.submenu}>
            <ul>
                <li>
                    <NavLink to={"/posts"} className={({ isActive }) => (isActive ? styles.active : '')}>
                        <img src={homeIcon} alt="Home" />
                        <img src={homeIconActive} className={styles.iconActive} alt="Home" />
                    </NavLink> 
                </li> 
                <li>
                <NavLink to={"/profile"} className={({ isActive }) => (isActive ? styles.active : '')}>
                        <img src={men} alt="User"  />
                        <img src={menActive} alt="User" className={styles.iconActive} /> 
                        </NavLink>
                </li> 
                <li>
                    <NavLink to={"/createPost"} state={{ prevPath: history.location.pathname }} className={({ isActive }) => (isActive ? styles.active : '')}>
                        <img src={bhoup} alt="Loudst" className={styles.Loud_bhopu} />
                    </NavLink>
                </li> 
                <li className={styles.notify_icon}>
                <NavLink to={"/notification"} className={({ isActive }) => (isActive ? styles.active : '')}>
                    <img src={bell} alt="Bell"  />
                    <img src={bellActive} className={styles.iconActive}  alt="Bell"  />
                        <div className={styles.countNotify} id="notificationsCount">2</div>
                    </NavLink>
                </li> 
                <li> 
                     <a onClick={LogoutFn} href="#">
                       {currentUser.loggedIn ? ( <img src={logout} alt="logout"  />):
                       ( <img src={login}  alt="logout"  />)}
                    </a>
                </li>
            </ul>
        </div>
    )
}