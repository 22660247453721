import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux"; 
import styles from "./styles.module.css";
import { PostAuthor } from "../../components/PostFormats/PostAuthor";
import BloudPost from "../../components/PostFormats/BloudPost";
import PostFooter from "../../components/PostFormats/PostFooter";
import PostMedia from "../../components/PostFormats/postMedia";
import PostStance from "../../components/PostFormats/postStance";
import PollPost from "../../components/PostFormats/PollPost";
import EventPost from "../../components/PostFormats/EventPost";
import { Header } from "../../components/Headers";
import {LoginPopup } from "../../components/loginPopup/index"
import postServices from "../../services/post.services"; 
import { Loader } from "../../components/loader/loader";
let page = 1;
// let posts;

const PostsPage = (props) => {
  const navigate = useNavigate()

  let currentUser = useSelector((state) => state.authentication);
  const [userName, setuserName] = useState("Guest");
  const [loading, setLoading] = useState(false);
  const [AllPosts, setAllPosts] = useState([]);
  const [userId, setUserId] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [showLoginPopup, setshowLoginPopup] = useState(false);
  const observer = useRef()
  const lastPost = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(e => {
      if (e[0].isIntersecting) {
        page++;
        getAllpost(page)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading])

  function getAllpost(p,uid) {
    setLoading(true)
    let body = {
      isFreindsOnly: false,
      postCategory: "All",
      isMySelfOnly: false,
      postType: 0,
      isNearMe: false,
      latitude: null,
      page: p,
      sort: 0,
      isGroupsOnly: false,
      longitude: null,
      userId: uid
    };
    postServices
      .getPosts(body)
      .then((p) => {
        setLoading(false)
        setAllPosts(AllPosts => [...AllPosts, ...p.response]);
      })
      .catch((e) => {
        //console.log('e',e)
      });
  }

 function toggleLoginPopup(e){ 
  setshowLoginPopup(e)
 }


  useEffect(() => { 
    setLoading(true)
   },[])
  useEffect(() => {  
    getAllpost(page ,currentUser.user.session_token );
     setuserName(currentUser.user.name);
     setUserId(currentUser.user.session_token); 
}, [currentUser]);
  function navtigateToPost(id) {
    console.log('test')
    // navigate('/post/'+id)
  }

  function postLists(posts) {
    let postList = [];
    if (posts.length > 0) {
      posts.forEach((post, i) => {
        post.currUser = userId;
        let videoObj = { video: post.video, videoThumbnail: post.videoThumbnail }
        let linkObj = { linkPreviewImage: post.linkPreviewImage, linkPreviewText: post.linkPreviewText, linkPreviewDescription: post.linkPreviewDescription, linkPreviewUrl: post.linkPreviewUrl }
        let images = []
        if (post.linkPreviewImage) {
          images.push(post.linkPreviewImage)
        } else if (post.images) {
          images = post.images
        }
        if (post.postType == 1) {


          if (posts.length === i + 3) {
            postList.push(
              <div key={post.postId}  className={styles.postWrap} ref={lastPost}>
                {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                <PostAuthor post={post} />
                <BloudPost postDetail={post} />
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
              </div>)
          } else {
            postList.push(
              <div key={post.postId}  className={styles.postWrap}  >

                {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                <PostAuthor post={post} />
                <BloudPost postDetail={post} />
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
              </div>)
          }
        } else if (post.postType == 2) {
          if (posts.length === i + 3) {
            postList.push(
              <div className={styles.postWrap} key={post.postId} ref={lastPost}>
                {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                <PostAuthor post={post} />
                <PollPost postDetail={post} />
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
              </div>
            );
          } else {
            postList.push(
              <div className={styles.postWrap} key={post.postId}>
                {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                <PostAuthor post={post} />
                <PollPost postDetail={post} />
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
              </div>
            );
          }
        } else if (post.postType == 3) {
          if (posts.length === i + 3) {
            postList.push(
              <div className={styles.postWrap} key={post.postId} ref={lastPost}>

                <EventPost
                  postDetail={post} 
                />
                <PostAuthor post={post} />
                {post.postText && <div className={styles.postTxt}>
                  <p>{post.postText}</p>
                </div>
                }
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup} />
              </div>
            );
          } else {
            postList.push(
              <div className={styles.postWrap} key={post.postId} ref={lastPost}>
                 {(images.length > 0 || videoObj.video) && <PostMedia postMedia={images} postVideo={videoObj} linkObj={linkObj} />}
                <EventPost
                  postDetail={post} 
                />
                <PostAuthor post={post} />
                {post.postText && <div className={styles.postTxt}>
                  <p>{post.postText}</p>
                </div>
                }
                <PostStance postDetail={post} setLoginPopup={toggleLoginPopup} />
                <PostFooter postDetail={post} setLoginPopup={toggleLoginPopup}  />
              </div>
            );
          }
        }
      });
    }
    return postList;
  }

  return (
    <>
      <Header userName={userName} />
      <div className={styles.pageWrap}>
        <div className={styles.postContainer}>
          {AllPosts && postLists(AllPosts)}
        </div>
        {loading && 
       <div className={styles.pageLoader}>
         <Loader/>
       </div> 
        }
      </div>
       
      <div className={[styles.loginPopup,showLoginPopup ? styles.slideIn : ''].join(' ')}>
        <LoginPopup setLoginPopup={toggleLoginPopup}  />
      </div>
    </>
  );
};

export { PostsPage };
