import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.css'
import { FaMicrophone, FaVideo, FaCamera, FaCog, FaWindowClose, FaTimes } from 'react-icons/fa';
import { Modal } from '../../modal'; 
export const Footer = (props) => {
    const [videoObj, setVideoObj] = useState({ 'videofile': '', 'videoName': '', 'showvideo': false });
    const [disableInp, setDisableInp] = useState(false);
    const [imgObj, setImgObj] = useState({ 'imgFile': [], 'showImg': false })
    const videoRef = useRef(null);
    const imgRef = useRef(null);
    const [settingModal, setSettingModal] = useState(false)
    const [postVisibilty, setPostVisibilty] = useState('public')
    const [selectedCategory, setSelectedCategory] = useState('') 
    const [categories, setCategories] = useState([])
    const actTab = useSelector((state) => state.posts.tabAct)
    const [thumbnail, setThumbnail] = useState(null);

    // const fetchCategories = () => { 
    //     postServices.getCategories(props.userData._id)
    //         .then((resp) => {
    //             if (resp.isSuccess == 1) {
    //                 setCategories(resp.response)
    //             }
    //         });
    // }

    const showImage = (event) => {
        const imgFile = [...event.target.files]
        let imgUpdate = {};
        let imgHtml = [];
        if (event.target.files.length) {
            imgFile.forEach((img, i) => {
                imgHtml.push(
                    <li key={i}><img src={URL.createObjectURL(img)} /></li>
                );
            });
            imgUpdate = {
                'showImg': true,
                'imgFile': imgHtml
            }
            setImgObj(imgUpdate)
        } else{
            clearFiles('img')
        }
    }

    const showSettingModal = () => {
        setSettingModal(true)
    }

    const showVideo = (event) => {
        if (event.target.files.length) {
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const url = URL.createObjectURL(event.target.files[0]);
            video.src = url;
            video.currentTime = 1; // Seek to 1 second into the video for the thumbnail
      
            video.onloadeddata = () => {
              video.currentTime = 1; // Seek to 1 second (you can adjust this as needed)
            };
      
         const thumbnailUrl =   video.onseeked = () => {
                // Set canvas dimensions same as video
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
        
                // Draw the frame from the video to the canvas
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
                // Convert the canvas to an image URL and set as thumbnail
                const url =  canvas.toDataURL('image/png');
                
                //return url;
                // Cleanup
                setThumbnail(url)
                URL.revokeObjectURL(url);
                
              };
            const videoUpdate = {
                'showvideo': true,
                'videofile': URL.createObjectURL(event.target.files[0]),
                'videoName': event.target.files[0].name,
            }
            setVideoObj(videoUpdate)
        } else {
            clearFiles('video')
        }
    }

    // useEffect(() => {
    //     fetchCategories();
    // }, [props.userData])

    const setChannel = (channelName) => {

        let cats = [...categories]
        cats.map((cat) => {
            if (cat.name == channelName) {
                cat.isSelected = 1
                setSelectedCategory(cat.name)
            } else {
                cat.isSelected = 0
            }
            return cat.name == channelName ? cat : null
        })

        setCategories(cats)
    }

    function closeModal(e, modalState) {
        if(e){
            e.preventDefault();
        }
    setSettingModal(modalState)
    }
    const clearFiles = (inputType) => { 
        if (inputType == 'video') {
            const videoUpdate = {
                'showvideo': false,
                'videofile': '',
                'videoName': '',
            }
            setVideoObj(videoUpdate)
            videoRef.current.value = "";
        } else if (inputType == 'img') {
            imgRef.current.value = ""
            setImgObj({ 'showImg': false, 'imgFile': [] })
        }
    }
    function submitForm(){
        props.submitform(actTab,imgRef,videoRef,thumbnail) 
    }
    useEffect(()=>{
        const videoUpdate = {
            'showvideo': false,
            'videofile': '',
            'videoName': '',
        }
        setVideoObj(videoUpdate)
         setImgObj({ 'showImg': false, 'imgFile': [] })
    },[actTab])
    return (
        <>
            <div className={styles.videoPreview} style={{ display: videoObj.showvideo ? 'block' : 'none' }}>
                
                <video width="100%" poster={thumbnail} height="auto" id="postVideo" src={videoObj.videofile} preload>
                </video>
                <label>{videoObj.videoName}</label>
                <span className={styles.close} onClick={() => { clearFiles('video') }}><FaWindowClose /></span>
            </div>
            <div className={styles.imgPreview} style={{ display: imgObj.showImg ? 'inline-block' : 'none' }}>
                <ul>
                    {imgObj.imgFile}
                </ul>
                <span className={styles.close} onClick={() => { clearFiles('img') }}><FaWindowClose /></span>
            </div>
            <div className={styles.footer}>
                <div className={styles.leftSec}>
                    <ul>
                        {actTab != 'poll' &&
                            <>
                                {/* <li>
                                    <a className="audio_input" href="#audio_input">
                                        <FaMicrophone />
                                    </a>
                                </li> */}
                                <li className={[styles.custom_file, imgObj.showImg ? styles.disabled : ''].join(' ')}>
                                    <FaVideo />
                                    <input type="file" onClick={(e) => e.stopPropagation} name="file" accept="video/mp4" id="video" onChange={showVideo} ref={videoRef}
                                        disabled={imgObj.showImg || disableInp} />
                                </li>
                                <li className={[styles.custom_file, videoObj.showvideo ? styles.disabled : ''].join(' ')}>
                                    <FaCamera />
                                    <input type="file" onClick={(e) => e.stopPropagation} name="file" accept="image/jpeg,image/png,image/gif" id="image" onChange={showImage} multiple ref={imgRef} disabled={videoObj.showvideo || disableInp} />
                                </li>
                            </>
                        }
                        <li>
                            <a onClick={showSettingModal}>
                                <FaCog />
                            </a>
                        </li>
                        <li className={styles.tags}>
                            <span className={styles.postVisibilitySelected}>{postVisibilty}</span>
                            {selectedCategory && <span className={styles.postVisibilitySelected}>{selectedCategory}</span>}
                        </li>
                    </ul>
                </div>
                <div className={styles.rightSec}>
                    <button onClick={submitForm} type='submit'>POST</button>
                </div>
            </div>
            <Modal visible={settingModal} onClose={(e) => { closeModal(e,false) }}>
                <div className={styles.modalInner}>
                    <div className={styles.modalHeader}>
                        <a href="#" onClick={(e) => { closeModal(e, false) }} className={styles.closeModal} ><FaTimes color="#616060" size="25px" /></a>
                        <h2>Post Setting</h2>
                    </div>
                    <div className={styles.postVisibility}>
                        <div className={styles.radioInput}>
                            <label htmlFor="public">
                                <input id="public" name="group1" className="group1" type="radio" checked={postVisibilty == 'public' ? true : false} onChange={() => { setPostVisibilty('public') }} value="1" />
                                <span>Public</span>
                            </label>
                        </div>
                        <div className={styles.radioInput}>
                            <label htmlFor="friends">
                                <input id="friends" name="group1" className="group1" type="radio" checked={postVisibilty == 'friends' ? true : false} onChange={() => { setPostVisibilty('friends') }} value="2" />
                                <span>Friends</span>
                            </label>
                        </div>
                    </div>

                    <div className={styles.categories}>
                        <h5>Channel</h5>
                        <ul>
                            {categories && categories.map((cat, i) => {
                                return <li key={i} className={cat.isSelected == 1 ? styles.isActive : ''} onClick={() => { setChannel(cat.name) }}>{cat.name}</li>
                            })}
                        </ul>
                    </div>
                    <div className={styles.btn}>
                        <button>Apply</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}