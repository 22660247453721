import {useState, useEffect , React } from "react"; 
import  { FaFacebookF, FaTwitter,FaEnvelope ,  FaInstagram , FaYoutube, FaLinkedinIn , FaFacebookMessenger, FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import styles from "./styles.module.css";
import Slider from "react-slick";
import "./slick.min.css";
// import "./slick-theme.min.css";
import LoudstLogo from "../../assets/site-img/loudst-logo.png";
import loudstName from "../../assets/site-img/loudst-name.png";
import sectionImg from "../../assets/site-img/section1-pic.jpg";
import p1 from "../../assets/site-img//app/p1.png";
import p2 from "../../assets/site-img//app/p2.png";
import p3 from "../../assets/site-img//app/p3.png";
import p4 from "../../assets/site-img//app/p4.png";
import p5 from "../../assets/site-img//app/p5.png";
import p6 from "../../assets/site-img//app/p6.png";
import p7 from "../../assets/site-img//app/p7.png";
import p8 from "../../assets/site-img//app/p8.png";
import p9 from "../../assets/site-img//app/p9.png";
import pt1 from "../../assets/site-img/talk/p1.jpg";
import pt2 from "../../assets/site-img/talk/p2.jpg";
import pt3 from "../../assets/site-img/talk/p3.jpg";
import pt4 from "../../assets/site-img/talk/p4.jpg";
import pt5 from "../../assets/site-img/talk/p5.jpg";
import pt6 from "../../assets/site-img/talk/p6.jpg";
import pt7 from "../../assets/site-img/talk/p7.jpg";
import section2pic1 from "../../assets/site-img/section2-pic1.jpg";
import section2pic2 from "../../assets/site-img/section2-pic2.jpg";
import section2pic3 from "../../assets/site-img/section2-pic3.jpg";
import nitin from "../../assets/site-img/team/nitin.jpg";
import anuradha from "../../assets/site-img/team/anuradha.jpg";
import swapnil from "../../assets/site-img/team/swapnil.jpg";

const HomePage = (props) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState('');
    var settings1 = {
        arrows:false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    var settings2 = {
        arrows:false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      };
      const [formData, setFormData] = useState({
        iTopic: '',
        iOther: '',
        iName: '',
        iPhone: '',
        iEmail: '',
        iMessage: '',
      });
      
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
      
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
      };
     
      useEffect(() => {
        window.addEventListener("scroll", () => {
          console.log('scroll',window.scrollY > 50)
          setScroll(window.scrollY > 150);
        });
      }, []);
  return (
    <div className={styles.homePage}>
      <div className={styles.header}>
        <div className={styles["bg-color"]}>
          <header id="main-header">
            <nav
              className={
                [ scroll ? styles["top-nav-collapse"] : '' ,
                styles["navbar"] ,
                styles["navbar-default"] ,
                styles["navbar-fixed-top"] ].join(' ')
              
              }
            >
              <div className={styles.container}>
                <div className={styles["navbar-header"]}>
                  <button
                    type="button"
                    className={
                      styles["navbar-toggle"] + " " + styles["loudst-button"]
                    }
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className={styles["icon-bar"]}></span>
                    <span className={styles["icon-bar"]}></span>
                    <span className={styles["icon-bar"]}></span>
                  </button>
                  <a className={styles["navbar-brand"]} href="#">
                    <img
                      className={styles["loudst-logo"]}
                      src={LoudstLogo}
                      alt="Loudst Logo"
                    />
                    <img
                      className={styles["loudst-name"]}
                      src={loudstName}
                      alt="Loudst Name"
                    />
                  </a>
                </div>
                <div
                  className={
                    styles["collapse"] + " " + styles["navbar-collapse"]
                  }
                  id="myNavbar"
                >
                  <ul
                    className={
                      styles.nav +
                      " " +
                      styles["navbar-nav"] +
                      " " +
                      styles["navbar-right"] +
                      " " +
                      styles["loudst-menu"]
                    }
                  >
                    {/* <li className={styles.active}><a href="#main-header">Home</a></li> */}
                    <li>
                      <a href="#join">JOIN THE CONVERSATION {scroll}</a>
                    </li>
                    <li>
                      <a href="#story">The Story</a>
                    </li>
                    <li>
                      <a href="#platform">The Platform</a>
                    </li>
                    <li>
                      <a href="#team">The Team</a>
                    </li>
                    {/* <li><a href="#blog">Blog</a></li>
                <li><a href="#contact">Contact Us</a></li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </header>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div className={styles.row}>
                <div className={styles["header-section"]}>
                  <div
                    className={
                      styles["banner-info"] +
                      " " +
                      styles["wow"] +
                      " " +
                      styles["fadeIn"] +
                      " " +
                      styles["delay-05s"] +
                      " " +
                      styles["header-content"]
                    }
                  >
                    {/* <h1 className={styles["bnr-title"]}>We are at ba<span>ker</span></h1> */}
                    <h2 className={styles["bnr-sub-title"]}>
                      The Social Conversation Platform
                    </h2>
                    <p className={styles["bnr-para"]}>
                      Enabling change via #notrolling #noshouting conversations
                    </p>
                    <div
                      className={
                        styles["join-btn"] +
                        " " +
                        styles.btn1 +
                        " " +
                        styles["ld-button"] +
                        " " +
                        styles["banner-buttons"]
                      }
                    >
                      Join the Conversation
                    </div>
                    <a
                      className={
                        styles["join-btn"] +
                        " " +
                        styles.btn1 +
                        " " +
                        styles["ld-button"] +
                        " " +
                        styles["web-app-login"] +
                        " " +
                        styles["banner-buttons"]
                      }
                      href="#"
                      onClick={(e)=>{e.preventDefault(); navigate('/login'); }}
                    >
                      Web App Login
                    </a>
                    {/* <div className={styles["overlay-detail"]}>
                <a href="#feature"><i className="fa fa-angle-down"></i></a>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        id="story"
        className={`${styles["section-padding"]} ${styles.wow} ${styles["fadeIn"]} ${styles["delay-05s"]}`}
      >
        <div className={styles.container}>
          <div className={styles.padding}></div>
        </div>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <h2 className={`${styles["section-title"]} ${styles.pad1}`}>
                The Story
              </h2>
              <h3 className={`${styles["sub-title"]} ${styles["pad-bt15"]}`}>
                In pursuit of meaningful conversations
              </h3>
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={`${styles["col-md-12"]} ${styles["col-sm-12"]} ${styles["col-xs-12"]}`}
            >
              <div>
                <img
                  src={sectionImg}
                  style={{ width: "100%" }}
                  alt="Section 1"
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <br />
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={`${styles["col-md-12"]} ${styles["col-sm-12"]} ${styles["col-xs-12"]}`}
            >
              <p>
                Ours is a story of a pursuit. The pursuit of meaningful
                conversation. Conversations, we believe, bring change. In our
                quest for civil discourses though, we encountered cacophony:
                one-upmanship, outrage, whataboutery, ridicule, and abuse. In
                the fabulously connected world of ours, most discussions have
                been happening in digressing group chats or the noisy comments
                section. Mostly devoid of meaning. Rarely civil. With emotions
                strewn all around.
              </p>
              <p>
                We observed the trolling online. We frowned upon the shouting
                matches on TV. As we analyzed the collective psyche, we found
                that the cacophony resulted in anxiety, which further fed noise
                to the world of information. Over months of deliberation thus,
                four thinkers turned founders. The absence of civility and
                structure in the current methods of communication led us to
                create LoudST, a <b>social conversation platform</b>, a first.
                Our purpose was simple: to enable change by bringing meaning
                back into conversation.
              </p>
              <p>
                What kind of change do we talk about? A change in self. A change
                in society. A change in policy. LoudST is for individual
                thinkers, for change agents, for organizations, for corporates,
                for activists. LoudST aims to serve everyone who seeks either
                knowledge or perspective.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="platform"
        className={`${styles["section-padding"]} ${styles.wow} ${styles["fadeInUp"]} ${styles["delay-05s"]} ${styles["alt-section"]}`}
      >
        <div className={styles.container}>
          <div className={styles.padding}></div>
        </div>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <h2 className={`${styles["section-title"]} ${styles.pad1}`}>
                The Platform - TALK and TECH
              </h2>
              <h3 className={`${styles["sub-title"]} ${styles["pad-bt15"]}`}>
                Ensuring civility and structure in conversation
              </h3>
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}
            >
              <img
                src={section2pic1}
                style={{ width: "100%" }}
                alt="Section 2 Pic 1"
              />
            </div>
            <div
              className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}
            >
              <img
                src={section2pic2}
                style={{ width: "100%" }}
                alt="Section 2 Pic 2"
              />
            </div>
            <div
              className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}
            >
              <img
                src={section2pic3}
                style={{ width: "100%" }}
                alt="Section 2 Pic 3"
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <br />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <p>
                At LoudST, we believe that every person is a thinker and thus, a
                potential influencer. This belief makes the platform medium
                agnostic. While one person may find comfort in a tech app,
                another may want to have a real-time conversation. At LoudST, we
                are constantly looking at creating comfortable spaces for
                individuals and organisations to seek and share.
              </p>
              <div className={styles["carousel-title"]}>
                <h3 style={{ color: "var(--loudst-color)" }}>LoudST TALK</h3>
                <p>
                  <b>#NoShouting</b> Conversations
                </p>
              </div>
              <p>
                <b>The LoudST TALK</b> is our collection of real-time
                conversations organized in the form of on-camera panel
                discussions (Fireside Chat with Thinkers), video conversations
                (TalkLoudST via Instagram, Facebook, Zoom, etc.), informative
                tweet chats, and the like. The videos of these conversations are
                later made available to the public.
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <div className={`${styles["loudst-car1"]}`}>
              <Slider {...settings1}>
                <div>
                  <img src={pt1} alt="Talk Pic 1" />
                </div>
                <div>
                  <img src={pt2} alt="Talk Pic 2" />
                </div>
                <div>
                  <img src={pt3} alt="Talk Pic 3" />
                </div>
                <div>
                  <img src={pt4} alt="Talk Pic 4" />
                </div>
                <div>
                  <img src={pt5} alt="Talk Pic 5" />
                </div>
                <div>
                  <img src={pt6} alt="Talk Pic 6" />
                </div>
                <div>
                  <img src={pt7} alt="Talk Pic 7" />
                </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <hr style={{ width: "50%" }} />
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <div className={`${styles["carousel-title"]} ${styles.app}`}>
                <h3 style={{ color: "var(--loudst-color)" }}>LoudST TECH</h3>
                <p>
                  <b>#NoTrolling</b> Conversations
                </p>
              </div>
              <a onClick={(e)=>{e.preventDefault(); navigate('/login'); }}
                className={`${styles["join-btn"]} ${styles["ld-button"]} ${styles["web-app-login"]}`}
                style={{ marginBottom: "10px" }}
              >
                Web App Login
              </a>
              <a
                className={`${styles["join-btn"]} ${styles["ld-button"]} ${styles["download-android-app"]}`}
                style={{ marginBottom: "10px", marginLeft: "15px" }}
              >
                Download App
              </a>
              <p>
                <b>The LoudST TECH</b> is our technology platform that includes
                our native mobile apps and web/mobile browser sites. This allows
                users to engage in structured debates and polls, and create
                personal and social events.
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles["col-md-12"]}>
              <div className={styles["loudst-car2"] }>
                <Slider {...settings2}>
                <div>
                  <img src={p1} alt="App Pic 1" />
                </div>
                <div>
                  <img src={p2} alt="App Pic 2" />
                </div>
                <div>
                  <img src={p3} alt="App Pic 3" />
                </div>
                <div>
                  <img src={p4} alt="App Pic 4" />
                </div>
                <div>
                  <img src={p5} alt="App Pic 5" />
                </div>
                <div>
                  <img src={p6} alt="App Pic 6" />
                </div>
                <div>
                  <img src={p7} alt="App Pic 7" />
                </div>
                <div>
                  <img src={p8} alt="App Pic 8" />
                </div>
                <div>
                  <img src={p9} alt="App Pic 9" />
                </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
  id="team"
  className={`${styles["section-padding"]} ${styles.wow} ${styles["fadeInUp"]} ${styles["delay-05s"]}`}
>
  <div className={styles.container}>
    <div className={styles.padding}></div>
  </div>
  <div className={styles.container}>
    <div className={styles.row}>
      <div className={styles["col-md-12"]}>
        <h2 className={`${styles["section-title"]} ${styles.pad1}`}>The Team</h2>
      </div>

      <div className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}>
        <div className={styles["profile-card"]}>
          <img className={styles.pic} src={swapnil} alt="SWAPNIL MANISH" />
          <div className={styles.name}>SWAPNIL MANISH</div>
          <div className={styles.title}>Co-Founder, Chief Executive Officer</div>
        </div>
      </div>

      <div className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}>
        <div className={styles["profile-card"]}>
          <img className={styles.pic} src={anuradha} alt="ANURADHA EXWAIZED" />
          <div className={styles.name}>ANURADHA EXWAIZED</div>
          <div className={styles.title}>Co-Founder, Chief Brand Officer</div>
        </div>
      </div>

      <div className={`${styles["col-md-4"]} ${styles["col-sm-6"]} ${styles["col-xs-12"]}`}>
        <div className={styles["profile-card"]}>
          <img className={styles.pic} src={nitin} alt="NITIN MISTRY" />
          <div className={styles.name}>NITIN MISTRY</div>
          <div className={styles.title}>Chief Technology Officer</div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="join" className={`${styles["section-padding"]}  ${styles["alt-section"]}`}>
  <div className={styles.container}>
    <div className={styles.padding}></div>
  </div>
  <div className={styles.container}>
    <div className={styles.row}>
        <div className={styles.colMd12}>
          <h2 className={`${styles.sectionTitle} ${styles.pad1}`}>Join the Conversation</h2>
        </div>
      </div>
      <div className={styles.row}>
      <div className={styles['col-md-6']}>
      <h3 style={{ color: 'var(--loudst-color)' }}>LoudST TALK</h3>
      <p>
        Appearance for thinkers - On-camera, in-person conversations
        (#FiresideChatWithThinkers/#FCT) | Live videos | Social media events
      </p>
      <br />
      <h4 style={{ fontSize: '20px' }}>Want to join our next TALK?</h4>
      <form onSubmit={handleSubmit} role="form" className={styles['contactForm']}>
        <div className={styles['form-container']}>
          <div className={styles.row} style={{ paddingTop: '20px' }}>
            <div className={styles['col-md-12']}>
              <label className={styles['mdb-main-label']}>Choose your topic</label>
              <select
                name="iTopic"
                id="iTopic"
                value={formData.iTopic}
                onChange={handleInputChange}
                required
                className={styles['mdb-select']}
              >
                <option value="1">Water crisis</option>
                <option value="2">Manual scavenging</option>
                <option value="3">Domestic workers' rights</option>
                <option value="4">Mob justice</option>
                <option value="5">Small-town-big-city difference</option>
                <option value="6">Corporate India & gender stereotypes</option>
                <option value="other">OTHER</option>
              </select>
              
            </div>
          </div>

          {/* Show Other topic input field conditionally */}
          {formData.iTopic === 'other' && (
            <div className={styles.row}>
              <div className={styles['col-md-12']}>
                <div className={styles['md-form']}>
                  <textarea
                    name="iOther"
                    id="iOther"
                    value={formData.iOther}
                    onChange={handleInputChange}
                    className={styles['md-textarea']}
                    rows="1"
                    required
                    placeholder="Your topic"
                  />
                </div>
              </div>
            </div>
          )}

          <div className={styles.row}>
            <div className={styles['col-md-12']}>
              <div className={styles['md-form']}>
                <textarea
                  name="iName"
                  id="iName"
                  value={formData.iName}
                  onChange={handleInputChange}
                  className={styles['md-textarea']}
                  rows="1"
                  required 
                />
                <label  className={styles['ta-label']} htmlFor="iName">Your name</label>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles['col-md-6']}>
              <div className={styles['md-form']}>
                <textarea
                  name="iPhone"
                  id="iPhone"
                  value={formData.iPhone}
                  onChange={handleInputChange}
                  className={styles['md-textarea']}
                  rows="1"
                  required 
                />
                 <label className={styles['ta-label']} htmlFor="iMessage">
                 Your Phone
                </label>
              </div>
            </div>
            <div className={styles['col-md-6']}>
              <div className={styles['md-form']}>
                <textarea
                  name="iEmail"
                  id="iEmail"
                  value={formData.iEmail}
                  onChange={handleInputChange}
                  className={styles['md-textarea']}
                  rows="1"
                  required 
                />
                 <label className={styles['ta-label']} htmlFor="iMessage">
                 Your e-mail
                </label>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles['col-md-12']}>
              <div className={styles['md-form']}>
                <textarea
                  name="iMessage"
                  id="iMessage"
                  value={formData.iMessage}
                  onChange={handleInputChange}
                  className={styles['md-textarea']}
                  rows="3" 
                />
                <label className={styles['ta-label']} htmlFor="iMessage">
                  Your message
                </label>
              </div>
              <button type="submit" className={styles['join-btn']}>
                Yes, I am in
              </button>
              <br />
              <br />
              <div id="sendmessage">Your message has been sent. Thank you!</div>
              <div id="errormessage"></div>
            </div>
          </div>
        </div>
      </form>
      <br />
      <div className={styles.row}>
        <div className={styles['col-md-6']}>
          <h4>Recent TALKS</h4>
          <ul className={styles['talk-links']}>
            <li>
              <a href="https://www.youtube.com/watch?v=_uD_8GOKniI" target="_blank" rel="noreferrer">
                Key questions on CAA &amp; NRC
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=lz0j4f2Z6WE" target="_blank" rel="noreferrer">
                Hyderabad FCT | Environment & Politics
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=HaHEFt0TlFI" target="_blank" rel="noreferrer">
                Rural FCT | Mob justice
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=ejGmfeE2TfA" target="_blank" rel="noreferrer">
                Dhanbad FCT | The-Small-Town-and-Big-City-Divide
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=qPSLGKQYS8s" target="_blank" rel="noreferrer">
                Ranchi FCT | Patriarchy
              </a>
            </li>
          </ul>
        </div>
        <div className={styles['col-md-6']}>
          <h4>Previous TALKS</h4>
          <ul className={styles['talk-links']}>
            <li>
              <a href="https://www.youtube.com/watch?v=Nw5rgg06Jx4" target="_blank" rel="noreferrer">
                India's Water Crisis
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=-hvu_h_r2dk" target="_blank" rel="noreferrer">
                PoSH Act, 2013
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=X6kt7VVzdHY" target="_blank" rel="noreferrer">
                Bengaluru FCT | Corporate India and gender stereotypes
              </a>
            </li>
          </ul>
        </div>
        
      </div>
      
    </div>
    <div className={styles['col-md-6']}>
      <h3 style={{ color: 'var(--loudst-color)' }}>LoudST TECH</h3>
      <p>
        The technology platform - Apps, Web app/mobile site - Civil and structured format to seek and share views and more
      </p>
      <br />
      <h4 style={{ fontSize: '20px' }}>Meet your TECH allies</h4>
      <p><b>Bloud:</b> A structured debate with clarity on agreements and disagreements. Spark a debate or join one</p>
      <p><b>Poll:</b> Create a poll or vote anonymously on others' polls</p>
      <p><b>Act:</b> Create or join an event - online or otherwise</p>
      <br />
      <a onClick={(e)=>{e.preventDefault(); navigate('/login'); }} href="#" className={`${styles['join-btn']} ${styles['web-app-login']} ${styles['ld-button']}`}>
        Web App Login
      </a>
      <p style={{ fontSize: '14px' }}>Sign up/log in from your mobile or desktop</p>
      <a className={`${styles['join-btn']} ${styles['download-android-app']} ${styles['ld-button']}`}>
        Download App
      </a>
      <p style={{ fontSize: '14px' }}>Download the LoudST Android app from Play Store for a better experience</p>
    </div>
      </div>
  </div>
</section>
<footer id="footer">
  <div className={styles.container} style={{ position: "relative" }}>
    <div className={`${styles.dFlex} ${styles["text-center"]}`}>
     <div className={styles["sm-button"]}><FaFacebookF /></div>
     <div className={styles["sm-button"]}><FaTwitter /></div>
     <div className={styles["sm-button"]}><FaInstagram /></div>
     <div className={styles["sm-button"]}><FaYoutube /></div>
     <div className={styles["sm-button"]}><FaLinkedinIn /></div>
     
    </div>
    <div className={`${styles["contact-us"]} ${styles["text-center"]}`}>
      <i id="emailLink" title="Email"><FaEnvelope /></i>
      <i id="messengerLink" title="Messenger"><FaFacebookMessenger /></i>
      <i id="whatsappLink" title="WhatsApp" ><FaWhatsapp /></i>
      <i id="telegramLink" title="Telegram"><FaTelegramPlane /></i>
    </div>
    <div className={`${styles.row} ${styles["text-center"]} ${styles.copyright}`}>
      <span>
        &copy; 2019 LoudST Digital Solutions Pvt. Ltd. All Rights Reserved.<br />
        <a className={styles["tos-link"]} href="#">
          Terms &amp; Conditions
        </a>
      </span>
    </div>
  </div>
</footer>

    </div>
  );
};
export { HomePage };
