import React  from 'react';
import styles from './styles.module.css'
import defaultImg from '../../../assets/icons/user-default.png'
import imgBck from '../../../assets/icons/img_back.png'
import { FaRegClock,FaThumbsUp } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';
import chatIcon from '../../../assets/icons/chatIcon.png'
import shareIcon from '../../../assets/icons/shareIcon.png'
import metricsIcon from '../../../assets/icons/metricsIcon.png'
import userShock from '../../../assets/emticon/userShock.png'
import userSad from '../../../assets/emticon/userSad.png'
import userAngry from '../../../assets/emticon/userAngry.png'
import userComment from '../../../assets/icons/user_comment.png'
import share from '../../../assets/icons/share.png'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import  userImgDefault from '../../../assets/icons/user-bloud.png'
export const PostFooter = (props) => {
    const navigate = useNavigate() 
    function toPostDetail(){
        if(!props.currUser){
            props.setLoginPopup(false); 
            navigate('/post/'+props.postDetail.postId)
            return;
        } else{
            props.setLoginPopup(true)
        }
    }
    return(
        <>
    <div className={styles.PostFooter}>
        <div className={styles.footerIcons}>
            <div className={styles.iconDiv} onClick={toPostDetail}>
                    <img src={chatIcon} alt="" />
                    <span>{props.postDetail.commentsCount}</span>
            </div>
            <div className={styles.iconDiv}>
                    <img src={shareIcon} alt="" />
                    <span>{props.postDetail.postShareCount}</span>
            </div>
            <div className={styles.iconDiv}>
                    <img src={metricsIcon} alt="" />
                    {/* <span>{props.postDetail.postShareCount}</span> */}
            </div>
        </div>
    </div>
    </>
    )
}
export default PostFooter