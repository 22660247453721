import {API} from '../constants/api.constants'
import { history } from '../helpers/history';
export const userServices = {
    login,
    verifyOTP,
    singUp,
    getUserData
};
async function singUp(userDetails,loginType) {
    let bodyString;
    let apiurl = API.SIGNUP_MOBILE;
    if(loginType==1){
        bodyString =  JSON.stringify(userDetails)
        apiurl = API.SIGNUP_EMAIL;
    }  else { 
        apiurl = API.SIGNUP_MOBILE;
        bodyString = JSON.stringify(userDetails)
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:bodyString
    };  
    return fetch(apiurl, requestOptions)
        .then(handleResponse)
        .then(user => {  
            return user;
        });
}
async function login(userDetails,loginType) {
    console.log('loginType',loginType)
    let bodyString;
    let apiurl = API.LOGIN_MOBILE;
    bodyString =  JSON.stringify(userDetails)
    if(loginType==1){
        apiurl = API.LOGIN_EMAIL;
    } else if(loginType==3){ 
        apiurl = API.LOGIN_SOCIAL;
    } else { 
        apiurl = API.LOGIN_MOBILE; 
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:bodyString
    };  
    return fetch(apiurl, requestOptions)
        .then(handleResponse)
        .then(user => {  
            return user;
        });
}
async function verifyOTP(otp) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(otp)
    };

    return fetch(API.VERIFYOTP, requestOptions)
        .then(handleResponse)
        .then(user => {  
            return user;
        });
}
async function getUserData(uid,session) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ session
         },
        body: JSON.stringify({"profile_user_id":uid})
    };

    return fetch(API.USER_WALL, requestOptions)
        .then(handleResponse)
        .then(user => {  
            return user;
        });
}


function handleResponse(response) {  
    if(response.status == "404")  return "Oops something went wrong";
    return response.text().then(text => {
        const data = text && JSON.parse(text); 
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });  
}
