import styles from './styles.module.css'
import {useEffect, useRef, useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'; 
import logo  from '../../assets/icons/logo_white.png';
import { useLocation  , useParams} from 'react-router-dom'

export const  HeaderTop = (props) => {  
    const params = useParams();
    const searchInp = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState('');
    const searchString = params.s;
    useEffect(()=>{
        setSearchVal(searchString)
    },[])
    function searchFn() {
        console.log(location.pathname);
        console.log(searchInp.current.value)
        if(searchInp.current.value){
            navigate('/search/'+searchInp.current.value)
        } 
    }
    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
      }
        
      const handleChange = (e) => {
        setSearchVal(e.target.value);
      };
      const processChanges = debounce(() => searchFn());
    return(
        <div className={styles.topHeader}>
            <div className={styles.logoSec}>
                <img className={styles.siteLogo} src={logo} alt="" />
                <div className={styles.userName}>
                <span> Welcome</span>
                {props.userName && <span>, {props.userName}</span>}
                {!props.userName && <span>, Guest</span>}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.searchBox}>
                    <form action="/post/postSearch">
                        <input type="text" placeholder="Search" value={searchVal} onChange={handleChange} onKeyUp={processChanges} ref={searchInp} />
                        <input type="submit" value=""  />
                    </form>
                    <span className={styles.searchClose}></span>
                </div>
            </div>
        </div>
    )
}